import { PublicClientApplication } from "@azure/msal-browser";
import MicrosoftSigninButton from "../components/MicrosoftSigninButton.vue";

export default {
  install (Vue) {
    // add $googleAuth to vue instance.
    Vue.prototype.$microsoftAuth = null;

    // create MSAL instance and assign to global $microsoftAuth variable.
    Vue.prototype.$createMSALInstance = function (params) {
      Vue.prototype.$microsoftAuth = new MsalLayer(params);
    }

    // register the component.
    Vue.component('microsoft-signin-button', MicrosoftSigninButton);
  }
}

class MsalLayer {
  constructor (config) {
    this.msal = new PublicClientApplication({ ...config });
  }

  get getMsalInstance () {
    return this.msal;
  }

  async loginPopup (scopes) {
    const response = await this.msal.loginPopup({ scopes: scopes });
    return response;
  }

  async loginRedirect (scopes) {
    const response = await this.msal.handleRedirectPromise();
    await this.msal.loginRedirect({ scopes: scopes });
    return response;
  }

  async logout () {
    const response = await this.msal.logoutPopup();
    return response;
  }

  user () {
    const currentAccounts = this.msal.getAllAccounts();
    if (!currentAccounts || currentAccounts.length === 0) return undefined;
    return currentAccounts[0];
  }

  async acquireToken (scopes) {
    let response;
    try {
      response = await this.msal.acquireTokenSilent({
        scopes: scopes,
        account: this.user()
      });
    } catch (error) {
      response = await this.msal.acquireTokenPopup({
        scopes: scopes,
        account: this.user()
      });
    }

    return response;
  }

  isAuthenticated () {
    const accounts = this.msal.getAllAccounts();
    return accounts && accounts.length > 0;
  }
}
