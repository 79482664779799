<script>
export default {
  name: 'google-signin-button',
  render (createElement) {
    return createElement('div', {
      attrs: {
        class: 'google-signin-button'
      },
      ref: 'signinBtn'
    })
  },
  props: {
    params: {
      type: Object,
      required: true
    },
    buttonConfigurations: {
      type: Object,
      required: false,
      default () {
        return {};
      }
    },
    openOneTapPrompt: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  async mounted () {
    try {
      await this.$loadScript();
    } catch (error) {
      console.error('"https://accounts.google.com/gsi/client" failed to be included as a <script>.', error);
    }

    this.initializeGoogleSignIn();
  },
  methods: {
    initializeGoogleSignIn () {
      if (!this.$googleAuth) {
        console.error('"https://accounts.google.com/gsi/client" needs to be included as a <script>.');
        return;
      }

      if (!this.params || (!this.params.clientId && !this.params.client_id)) {
        console.error('Client id must be specified.');
        return;
      }

      this.$googleAuth.accounts.id.initialize({
        ...this.params,
        callback: this.handleResponse,
        client_id: this.params.client_id || this.params.clientId
      });

      this.$googleAuth.accounts.id.renderButton(this.$refs.signinBtn, {
        ...this.buttonConfigurations
      });

      if (this.openOneTapPrompt) this.$google.accounts.id.prompt();
    },
    handleResponse (response) {
      if (!response) {
        console.error("Something went wrong during authentication. Please try again.");
        return;
      }

      if (response.credential) {
        this.$emit('success', response);
      } else {
        this.$emit('error', response);
      }
    }
  }
};
</script>
