import GoogleSinginButton from "../components/GoogleSinginButton.vue";

export default {
  install (Vue) {
    // load the "https://accounts.google.com/gsi/client" script.
    function loadGoogleScript () {
      return new Promise((resolve, reject) => {
        if (document.querySelector('script[src="https://accounts.google.com/gsi/client"]')) {
          resolve(window.google);
          return;
        }

        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;

        script.onload = () => {
          if (window.google) {
            resolve(window.google);
          } else {
            reject(new Error('"https://accounts.google.com/gsi/client" loaded, but window.google is not available.'));
          }
        };

        script.onerror = () => {
          reject(new Error('"https://accounts.google.com/gsi/client" failed to load.'));
        };

        document.head.appendChild(script);
      });
    }

    // add $googleAuth to vue instance.
    Vue.prototype.$googleAuth = null;

    // create a global promise to wait for Google API loading
    Vue.prototype.$loadScript = async function () {
      try {
        // make window.google available when script loaded in $googleAuth.
        Vue.prototype.$googleAuth = await loadGoogleScript();
      } catch (error) {
        throw new Error(error);
      }
    }

    // register the GoogleSigninButton component.
    Vue.component('google-signin-button', GoogleSinginButton);
  }
};
