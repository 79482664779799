<script>
import MicrosoftLogo from "../assets/microsoft-logo.png";

export default {
  name: "microsoft-signin-button",
  render (createElement) {
    return createElement("div", {
      ref: "signinBtn",
      class: ["ms-signin-button", this.buttonTheme],
      on: {
        click: this.handleClick
      }
    },
    [
      createElement("img", {
        class: "ms-logo",
        attrs: {
          src: MicrosoftLogo
        }
      }),
      this.buttonText
    ]);
  },
  props: {
    params: {
      type: Object,
      required: true
    },
    scopes: {
      type: Array,
      required: false,
      default () {
        return ["user.read"];
      }
    },
    text: {
      type: String,
      required: false,
      default: "signin_with"
    },
    theme: {
      type: String,
      required: false,
      default: "light"
    }
  },
  data () {
    return {
      allowedButtonValues: {
        signin: "Sign in",
        signin_with: "Sign in with Microsoft"
      },
      allowedTheme: {
        light: "light",
        dark: "dark"
      }
    }
  },
  computed: {
    buttonText () {
      if (this.allowedButtonValues[this.text]) {
        return this.allowedButtonValues[this.text];
      }

      return this.allowedButtonValues["signin_with"];
    },
    buttonTheme () {
      if (this.allowedTheme[this.theme]) {
        return this.allowedTheme[this.theme];
      }

      return this.allowedTheme["light"];
    }
  },
  mounted () {
    this.createInstance();
  },
  methods: {
    createInstance () {
      if (!this.params || !this.params.auth || !this.params.auth.clientId) {
        console.error("Client id must be specified.");
        return;
      }

      // call the plugin method to create msal instance.
      this.$createMSALInstance(this.params);
    },
    async handleClick () {
      if (!this.$microsoftAuth) {
        console.error("MSAL instance creation failed.");
        return;
      }

      try {
        const response = await this.$microsoftAuth.loginPopup(this.scopes);
        this.$emit("success", response);
      } catch (error) {
        this.$emit("error", error);
      }
    }
  }
}
</script>

<style scoped>
.ms-signin-button {
  font-family: 'Segoe UI';
  font-weight: 500;
  font-size: 14px;
  max-width: 400px;
  height: 40px;
  padding: 8px 12px;
  border-radius: 4px;
  letter-spacing: 0.25px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}

.light {
  color: #3c4043;
  border: 1px solid #dadce0;
}

.dark {
  color: #ffffff;
  background-color: #202124;
}

.ms-logo {
  margin-right: 12px;
}
</style>
