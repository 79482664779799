import Vue from "vue";
import Login from "./components/Login.vue";
import GoogleSigninButton from "./plugins/GoogleSigninButton";
import MicrosoftSigninButton from "./plugins/MicrosoftSigninButton";
Vue.use(GoogleSigninButton)
Vue.use(MicrosoftSigninButton)
let vm = new Vue({
  el: "#login",
  components: {
    Login
  },
  template: '<Login ref="LoginRef" />'

});
window.Login = vm;
